<template>
    <b-button
        class="btn btn-pdv-gradient-blue"
        :disabled="disabled"
        @click="$emit('onClick')"
    >
        {{ getI18n('ACTION.filter') }}
    </b-button>
</template>

<script>
export default {
    props: {
        disabled: {
            type: Boolean,
            required: false,
        },
    },
}
</script>

<script>
export default {
    data() {
        return {}
    },
    methods: {
        nullableField(value, defaultNull) {
            if (!defaultNull) {
                defaultNull = this.getI18n('COMMON', 'none')
            }
            return value === null ? defaultNull : value
        },
        createSearch(search) {
            let searchField = ''
            Object.keys(search).forEach((key) => {
                if (search[key] && search[key].length > 0) {
                    searchField = this.formatSearch(
                        searchField,
                        key,
                        search[key]
                    )
                }
            })
            return searchField
        },
        formatSearch(searchField, nextField, nextValue) {
            if (nextValue && nextValue.length > 0) {
                searchField = `${searchField}${
                    searchField.length > 0 ? ';' : ''
                }${nextField}:${nextValue}`
            }
            return searchField
        },
        dateToValue(date) {
            const d = new Date(date)
            let month = '' + (d.getMonth() + 1)
            let day = '' + d.getDate()
            const year = d.getFullYear()
            if (month.length < 2) month = '0' + month
            if (day.length < 2) day = '0' + day
            return [year, month, day].join('-')
        },
        formatFilterDate(date) {
            const { start, end } = date || {}
            if (start || end) {
                const formattedDate = {}
                if (start) {
                    formattedDate.from = this.dateToValue(start)
                }
                if (end) {
                    formattedDate.to = this.dateToValue(end)
                }
                return formattedDate
            }
            return ''
        },
        formatFromTo(from, to) {
            if (from || to) {
                const formattedFromTo = {}
                if (from) {
                    formattedFromTo.from = from
                }
                if (to) {
                    formattedFromTo.to = to
                }
                return formattedFromTo
            }
            return ''
        },
        formatParameters(additionalParameter) {
            Object.keys(additionalParameter).forEach((key) => {
                if (
                    additionalParameter[key] === null ||
                    additionalParameter[key] === undefined ||
                    additionalParameter[key] === '' ||
                    (Array.isArray(additionalParameter[key]) &&
                        additionalParameter[key].length === 0)
                ) {
                    delete additionalParameter[key]
                } else if (typeof additionalParameter[key] === 'object') {
                    this.formatParameters(key)
                }
            })
            return additionalParameter
        },
    },
}
</script>

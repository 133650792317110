<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    showSlot: {
        type: Boolean,
        default: false,
    }
  },
}
</script>

<template>
  <div class="row">
    <div :class="showSlot ? 'col-8' : 'col-12'">
      <div class="page-title-box">
        <h4 class="page-title">{{ title }}</h4>
      </div>
    </div>
    <div v-if="showSlot" class="col-4 d-flex flex-row-reverse align-items-center">
        <slot></slot>
    </div>
  </div>
</template>

<template>
    <b-form-group
        :label="label"
        label-for="modal-brand"
        :invalid-feedback="invalidFeedback"
        :class="`${required ? 'required ' : ''}label-pdv`"
    >
        <treeselect
            ref="treeselect"
            v-model="optionId"
            :options="options"
            :default-options="defaultOptions"
            :async="asyncSearch"
            :load-options="loadOptions"
            :normalizer="normalizer"
            class="select-validation"
            :placeholder="placeholder"
            :class="selectClass"
            :clearable="true"
            :multiple="multiple"
            :value-consists-of="valueConsistsOf"
            :default-expand-level="Infinity"
            :no-children-text="noChildrenText"
            :disable-branch-nodes="disableBranchNodes"
            :search-prompt-text="getI18n('PLACEHOLDERS', 'type_to_search')"
            :disabled="disabled"
            @input="inputFunction"
        />
    </b-form-group>
</template>

<script>
import {
    Treeselect,
    LOAD_ROOT_OPTIONS,
    LOAD_CHILDREN_OPTIONS,
    ASYNC_SEARCH,
} from '@riophae/vue-treeselect'
import BaseService from '@src/services/BaseService'

export default {
    components: {
        Treeselect,
    },
    props: {
        // eslint-disable-next-line vue/require-prop-types
        value: {
            default: null,
        },

        option: {
            type: Object,
            default: () => ({
                id: null,
                name: '',
                parent_id: null,
            }),
        },

        asyncSearch: {
            type: Boolean,
            default: false,
        },

        normalizer: {
            type: Function,
            default: (node) => ({
                label: node.name,
            }),
        },

        parameters: {
            type: Object,
            default: () => ({
                per_page: 1000,
                page: 1,
            }),
        },

        service: {
            type: String,
            required: true,
        },

        inputFunction: {
            type: Function,
            default: () => ({}),
        },

        selectClass: {
            type: String,
            default: '',
        },

        multiple: {
            type: Boolean,
            default: false,
        },

        invalidFeedback: {
            type: String,
            default: '',
        },

        label: {
            type: String,
            default: '',
        },

        valueConsistsOf: {
            type: String,
            default: 'BRANCH_PRIORITY',
        },

        placeholder: {
            type: String,
            default: '',
        },

        noChildrenText: {
            type: String,
            default: '',
        },

        disableBranchNodes: {
            type: Boolean,
            default: false,
        },

        required: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            options: null,
            defaultOptions: this.option.id ? [this.option] : false,
        }
    },

    computed: {
        optionId: {
            get() {
                return this.value
            },
            set(newValue) {
                const id = newValue || null
                this.$emit('input', id)
            },
        },
        serviceClass: function () {
            return new BaseService(this.service)
        },
    },
    async created() {
        this.options = await this.loadData(this.parameters)
    },
    methods: {
        clearInput() {
            this.optionId = null
        },

        loadData(parameters) {
            return this.serviceClass.fetchAll(parameters).then((response) => {
                return response.data.data
            })
        },

        async loadOptions({ action, parentNode, searchQuery, callback }) {
            const parameters = this.parameters
            if (action === ASYNC_SEARCH) {
                parameters.search = `name:${searchQuery}`
                parameters.searchFields = `name:ilike`

                const options = await this.loadData(parameters)
                callback(null, options)
            }
            if (action === LOAD_ROOT_OPTIONS) {
                parameters.parent_id = null
                this.options = await this.loadData(parameters)
                callback()
            }
            if (action === LOAD_CHILDREN_OPTIONS) {
                parameters.parent_id = parentNode.id
                parentNode.children = await this.loadData(parameters)
                callback()
            }
        },

        async refresh() {
            this.options = await this.loadData(this.parameters)
        },
    },
}
</script>

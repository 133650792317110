<script>
export default {
  components: {},
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<template>
    <div class="row">
        <div class="col-12">
            <b-breadcrumb class="page-breadcrumb" :items="items" />
        </div>
    </div>
</template>

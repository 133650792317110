<script>
export default {
    data() {
        return {
            modalResponseErrors: {},
        }
    },
    methods: {
        validationClass(validationToCheck) {
            if (!validationToCheck.$dirty) {
                return ''
            }
            return validationToCheck.$invalid ? 'is-invalid' : 'is-valid'
        },
        clearResponseError(key, nested) {
            if (this.hasValidationError(this.modalResponseErrors, key)) {
                delete this.modalResponseErrors[key]
            }
        },

        clearAllResponseError() {
            this.modalResponseError = {}
        },

        hasError(toValidate) {
            if (
                this.$v[toValidate.parent][toValidate.key].$dirty ||
                this.$v[toValidate.parent][toValidate.key].$error
            ) {
                return !this.$v[toValidate.parent][toValidate.key].$error
            }
            return null
        },
        validateState(key, parent) {
            return this.hasError({ key, parent })
        },
        getError(errors, key) {
            return errors[key]
        },
        hasValidationError(errors, key) {
            return Object.prototype.hasOwnProperty.call(errors, key)
        },
        validateField(key, nested) {
            const isValidForm = this.validateState(key, nested)
            const hasBackendError = this.hasValidationError(
                this.modalResponseErrors,
                key
            )
            return isValidForm && !hasBackendError
        },
        transformToCamelCase(error) {
            return error.replace(/([-_][a-z])/gi, ($1) => {
                return $1.toUpperCase().replace('-', '').replace('_', '')
            })
        },
    },
}
</script>

<template>
    <multiselect
        :id="id"
        v-model="selectedOptions"
        :class="selectClass"
        :label="label"
        track-by="id"
        :placeholder="placeholder"
        open-direction="bottom"
        :options="options"
        :multiple="multiple"
        :searchable="searchable"
        :loading="isLoading"
        :options-limit="perPage"
        :limit="limit"
        :limit-text="limitText"
        :max-height="600"
        :hide-selected="hideSelected"
        :disabled="disabled"
        :select-label="''"
        :selected-label="getI18n('MULTISELECT', 'selected_label')"
        :deselect-label="''"
        :close-on-select="!keepOpenOnSelect"
        :allow-empty="allowEmpty"
        :preselect-first="preselectFirst"
        @search-change="asyncFind"
        @input="inputFunction"
    >
        <span slot="noResult">{{
            getI18n('ERROR_CODES', 'search_not_found')
        }}</span>
        <template v-if="totalOptions > perPage" slot="afterList">
            <span class="resultLimit">{{
                getI18n('ERROR_CODES', 'too_many_results')
            }}</span>
        </template>
        <template slot="noOptions">{{
            getI18n('ERROR_CODES', 'empty_selector')
        }}</template>
    </multiselect>
</template>

<script>
import Multiselect from 'vue-multiselect'
import BaseService from '@src/services/BaseService'

export default {
    components: {
        Multiselect,
    },
    props: {
        id: {
            type: String,
            required: true,
        },
        multiple: {
            type: Boolean,
            required: false,
        },

        // eslint-disable-next-line vue/require-prop-types
        value: {
            default: null,
        },

        searchable: {
            type: Boolean,
            default: false,
        },

        service: {
            required: true,
            type: String,
        },

        selectClass: {
            type: String,
            default: '',
        },

        optionsToRemoveList: {
            type: Array,
            default: () => [],
        },

        inputFunction: {
            type: Function,
            default: () => ({}),
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        hideSelected: {
            type: Boolean,
            default: false,
        },

        keepOpenOnSelect: {
            type: Boolean,
            default: false,
        },

        label: {
            type: String,
            default: 'name',
        },

        searchLabel: {
            type: String,
            default: null,
        },

        limit: {
            type: Number,
            default: 3,
        },

        limitText: {
            type: Function,
            default: function (count) {
                return `${this.$options.filters.firstToLower(
                    this.getI18n('COMMON.and')
                )} ${count} ${this.$options.filters.firstToLower(
                    this.getI18nModified({
                        prefix: 'COMMON',
                        suffix: 'other_options',
                        modifier: count,
                    })
                )}`
            },
        },

        parameters: {
            type: Object,
            default: () => ({
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            }),
        },

        defaultOptions: {
            type: Array,
            default: () => [],
        },

        allowEmpty: {
            type: Boolean,
            // eslint-disable-next-line vue/no-boolean-default
            default: true,
        },

        preselectFirst: {
            type: Boolean,
            required: false,
        }
    },
    data() {
        return {
            isLoading: false,
            options: this.defaultOptions,
            totalOptions: 0,
            perPage: this.parameters.per_page || 1000,
        }
    },
    computed: {
        selectedOptions: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },

        placeholder() {
            return this.searchable
                ? this.getI18n('PLACEHOLDERS', 'type_to_search')
                : this.getI18n('PLACEHOLDERS', 'select_an_option')
        },

        serviceClass: function () {
            return new BaseService(this.service)
        },
    },
    watch: {
        options(value) {
            this.$emit('options', value)
        },
    },
    created() {
        this.loadOptions()
    },
    methods: {
        loadingEvent(loading) {
            this.isLoading = loading
            this.$emit('loading', loading)
        },

        async loadOptions(parameters) {
            this.loadingEvent(true)

            const data = await this.serviceClass
                .fetchAll(parameters ?? this.parameters)
                .then((response) => {
                    this.options = response.data.data

                    if (this.optionsToRemoveList.length > 0) {
                        this.removeOptions(this.optionsToRemoveList)
                    }

                    if (this.preselectFirst && this.options.length > 0 && (!this.selectedOptions || this.selectedOptions.length > 0)) {
                        this.selectedOptions = this.multiple ? [this.options[0]] : this.options[0]
                    }

                    this.loadingEvent(false)
                    this.totalOptions = response.data.meta.total
                    return response.data.data
                })

            return data
        },
        removeOptions(removeOptionsList) {
            this.options = this.options.filter((option) => {
                    const index = removeOptionsList.findIndex(find => typeof find === 'object' ? find.id : find === option.id)
                    if(index > -1) {
                        return null
                    }
                    return option
                }
            )
        },
        asyncFind(searchQuery) {
            this.loadingEvent(true)
            if (searchQuery.length > 0) {
                const searchLabel = this.searchLabel
                    ? this.searchLabel
                    : this.label
                const searchParameters = {
                    search: `${searchLabel}:${searchQuery}`,
                    searchFields: `${searchLabel}:ilike`,
                    ...this.parameters,
                }
                this.serviceClass
                    .fetchAll(searchParameters)
                    .then((response) => {
                        this.options = response.data.data
                        this.totalOptions = response.data.meta.total
                        this.loadingEvent(false)
                    })
            }
            else {
                this.loadOptions()
            }
        },
        clearAll() {
            this.selectedOptions = []
        },
        refresh() {
            this.clearAll()
            this.loadOptions()
        },
    },
}
</script>

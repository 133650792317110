<script>
import moment from 'moment';

export default {
    data() {
        return {
            dueDateTypes: [
                {
                    value: null,
                    text: this.getI18n('DASHBOARDS', 'TYPES.all'),
                },
                {
                    value: 'within_fifteen_days_expiration',
                    text: this.getI18n('DASHBOARDS', 'TYPES.within_fifteen_days_expiration'),
                },
                {
                    value: 'from_sixteen_to_thirty_days_expiration',
                    text: this.getI18n('DASHBOARDS', 'TYPES.from_sixteen_to_thirty_days_expiration'),
                },
                {
                    value: 'more_thirty_days_expiration',
                    text: this.getI18n('DASHBOARDS', 'TYPES.more_thirty_days_expiration'),
                },
            ],
        }
    },
    methods: {
        formatDayToExpiration(dueDate) {
            if (!dueDate) {
                return 0
            }

            const dueDateMoment = moment(dueDate, 'DD/MM/YYYY')
            const currentDate = moment().startOf('day')
            const days = moment.duration(dueDateMoment.diff(currentDate)).asDays()

            if (days > 30) {
                return `<i class="fas fa-circle icon-blue" /><label class="ml-2">${days}</label>`
            }
            else if (days > 15 && days <= 30) {
                return `<i class="fas fa-circle icon-orange" /><label class="ml-2">${days}</label>`
            }
            else {
                return `<i class="fas fa-circle icon-red" /><label class="ml-2">${days > 0 ? days : 0}</label>`
            }
        },
    },
}
</script>
